import { enqueueSnackbar } from "notistack";

export const _openSnackbar = (message, variant) => {
  enqueueSnackbar(message, {
    variant: variant || "default",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    autoHideDuration: 3000,
  });
};

export const checkPermission = (permArr, role) => {
  return permArr.includes(role);
};
export const generateColorByName = (name) => {
  if (!name) return "#6366F1"; // Default color if name is undefined

  // Define 5 template colors
  const colors = [
    "#6366F1", // Indigo
    "#22C55E", // Green
    "#EF4444", // Red
    "#F59E0B", // Amber
    "#06B6D4", // Cyan
  ];

  // Get the first letter and convert to uppercase for consistency
  const firstLetter = name.charAt(0).toUpperCase();

  // Get ASCII code of the first letter
  const charCode = firstLetter.charCodeAt(0);

  // Map the ASCII code to one of the 5 colors
  const colorIndex = charCode % 5;

  return colors[colorIndex];
};
